import axios from "axios";
import { getConfig } from "../../modules/Config/Config";
import { ProductionSite } from "../models/ProductionSitesModel";

type GetProductionSitesResponse = {
  productionSites: ProductionSite[]
}

type UpsertProductionSitesResponse = {
  productionSite: ProductionSite
}

async function getProductionSites(): Promise<ProductionSite[]> {
  const backendConfig = await getConfig("backend");

  return axios
    .get<GetProductionSitesResponse>(`${backendConfig.url}/productionsite`,
      {
        headers:
        {
          "Content-Type": "application/json",
        }
      })
    .then((response) => response.data.productionSites);
}

async function upsertProductionSite(productionSite: ProductionSite): Promise<ProductionSite> {
  const backendConfig = await getConfig("backend");

  return axios
    .put<UpsertProductionSitesResponse>(`${backendConfig.url}/productionsite`,
      productionSite,
      {
        headers:
        {
          "Content-Type": "application/json",
        }
      })
    .then((response) => response.data.productionSite);
}

export { getProductionSites, upsertProductionSite };
