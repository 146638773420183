import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Alert } from "react-bootstrap";
import * as actions from '../redux/ProductionSitesActions'
import { DetailedAlert } from "../../utils/DetailedAlert";
import { useEffect, useState } from "react";
import ProductionSitesCard from "../components/ProductionSitesCard";
import ProductionSitesProvider from "../redux/ProductionSitesContext";
import ProductionSiteEditModal from "../components/ProductionSiteEditModal";

const ProductionSitesPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.fetchProductionSites());
  }, [dispatch]);

  const state = useSelector(
    (state: any) => ({
      alert: state.productionSites.alert || null,
    }),
    shallowEqual
  );
  const alert = state.alert as DetailedAlert

  const handleDismissAlert = () => {
    dispatch(actions.dismissAlert())
  }

  const [showProductionSiteEditModal, setShowProductionSiteEditModal] = useState<boolean>(false);
  const productionSitesUIEvents = {
    showProductionSiteEditModal: () => { setShowProductionSiteEditModal(true) },
    hideProductionSiteEditModal: () => { setShowProductionSiteEditModal(false) },
  }

  return (
    <ProductionSitesProvider productionSitesUIEvents={productionSitesUIEvents}>
      <div>
        {alert && alert?.message && (
          <Alert variant="danger" onClose={handleDismissAlert} dismissible>
            <Alert.Heading>{alert.message}</Alert.Heading>
            {alert.url && <div>{`Call to ${alert.url}`}</div>}
            {alert.apiResponseCode && <div>{`ResponseCode: ${alert.apiResponseCode}`}</div>}
            {alert.apiMessage && <div>{`Message: ${alert.apiMessage}`}</div>}
            {alert.data && <div>{`Body: ${alert.data}`}</div>}
          </Alert>
        )}
        {showProductionSiteEditModal && <ProductionSiteEditModal />}
        <ProductionSitesCard />
      </div >
    </ProductionSitesProvider >
  )
}

export { ProductionSitesPage };