import React from 'react';

type Props = {
  copy: string;
  customStyle?: string;
  color?: string;
};

const CheckIconWithCopy = ({ copy, customStyle, color }: Props) => (
  <div className={customStyle}>
    <div className='icon'>
      <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <title>Icon/system/check</title>
        <path
          d="M20.415 8.273c0 .3-.1.537-.3.712l-7.725 7.762-1.463 1.463c-.25.2-.5.3-.75.3a.973.973 0 0 1-.712-.3L4.14 12.885c-.2-.2-.3-.45-.3-.75s.1-.537.3-.713L5.565 9.96c.25-.2.5-.3.75-.3.275 0 .512.1.712.3l3.15 3.15 7.05-7.05c.2-.2.438-.3.713-.3.25 0 .5.1.75.3l1.425 1.463c.2.2.3.45.3.75z"
          fill={(color) ? color : "#333"}
          fill-rule="evenodd" />
      </svg>
    </div>
    <div>{copy}</div>
  </div>
);

export default CheckIconWithCopy;
