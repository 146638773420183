import { getRoutingRules, getTestCases, postRoutingRules, putTestCases, runTestSuite } from '../api/RoutingRulesApi';
import { IRoutingRule, IRoutingRuleSet, IRoutingRuleSetChangeMetaData, IRuleChange } from '../models/RoutingRuleSetModel';
import { routingRulesSlice, callTypes } from "./RoutingRulesSlice";
import { getArticleTypes, getBrands, getChannels } from '../api/DropdownDataApi';
import { cloneTestCase, TestCase } from '../models/TestSuiteModel';
import { convertCSVDataToTestCases } from '../utils/TestCaseCSVHelpers';
import { createDetailedAlert, DetailedAlert } from '../../utils/DetailedAlert';
import _ from 'lodash';

const { actions: sliceActions } = routingRulesSlice;

export const fetchRoutingRules = () =>
    async (dispatch: (arg0: any) => void) => {
        dispatch(sliceActions.startCall({ callType: callTypes.get }));
        return getRoutingRules()
            .then(response => {
                const routingRuleSet = response
                dispatch(sliceActions.routingRulesFetched(routingRuleSet));
            })
            .catch(err => {
                const alert = createDetailedAlert(err, "Failure on GET for Routing Rules")
                dispatch(sliceActions.catchError({ alert, callType: callTypes.get }));
            });
    };

export const persistRoutingRuleSet = (ruleSet: IRoutingRuleSet, changedRoutingRules: IRoutingRule[], description: string) =>
    async (dispatch: (arg0: any) => void) => {
        dispatch(sliceActions.startCall({ callType: callTypes.post }));
        const updatedRuleSet = {
            ...ruleSet,
            description: description
        } as IRoutingRuleSet

        const metaData: IRoutingRuleSetChangeMetaData = {
            changes: _.map(changedRoutingRules, (rule) => {
                return {
                    ruleId: rule.sequenceNumber.toString(),
                    ruleName: rule.name,
                    ruleState: rule.routingRuleChange?.changeType,
                } as IRuleChange
            }
            ) as IRuleChange[]

        } as IRoutingRuleSetChangeMetaData

        return postRoutingRules(updatedRuleSet, metaData)
            .then(response => {
                const routingRuleSet = response
                dispatch(sliceActions.routingRulesFetched(routingRuleSet));
            })
            .catch(err => {
                const alert = createDetailedAlert(err, "Failure on POST for Routing Rules")
                dispatch(sliceActions.catchError({ alert, callType: callTypes.post }));
            });
    };

export const importRoutingRules = (file: File) =>
    async (dispatch: (arg0: any) => void) => {
        const text = await file.text()
        try {
            const routingRuleSet = JSON.parse(text) as IRoutingRuleSet
            //test for unique sequence numbers in incoming rules
            const duplicateRule = routingRuleSet.decisionRules.find((findRule, findIndex) => {
                if (routingRuleSet.decisionRules.some((someRule, someIndex) =>
                    someRule.sequenceNumber === findRule.sequenceNumber
                    && someIndex !== findIndex
                )) {
                    return findRule
                }
                return undefined
            })
            if (duplicateRule) {
                const alert = {
                    message: "Error during import of Routing Rules",
                    apiMessage: `Multiple rules are present with Sequence Number ${duplicateRule.sequenceNumber}`,
                } as DetailedAlert
                dispatch(sliceActions.catchError({ alert, callType: callTypes.file }));
            } else {
                dispatch(sliceActions.routingRulesImported(routingRuleSet));
            }
        } catch (error: any) {
            const alert = {
                message: "Error during import of Routing Rules",
                apiMessage: error.message,
                data: error.stack
            } as DetailedAlert
            dispatch(sliceActions.catchError({ alert, callType: callTypes.file }));
        }
    };

export const cancelRoutingRuleSetImport = () =>
    async (dispatch: (arg0: any) => void) => {
        dispatch(sliceActions.clearRoutingRuleSetImport());
    };

export const acceptRoutingRuleSetImport = () =>
    async (dispatch: (arg0: any) => void) => {
        dispatch(sliceActions.acceptRoutingRuleSetImport());
    };

export const fetchTestCases = () =>
    async (dispatch: (arg0: any) => void) => {
        dispatch(sliceActions.startCall({ callType: callTypes.get }));
        return getTestCases()
            .then(response => {
                const testCases = response
                dispatch(sliceActions.testCasesFetched(testCases));
            })
            .catch(err => {
                const alert = createDetailedAlert(err, "Failure on GET for Test Cases")
                dispatch(sliceActions.catchError({ alert, callType: callTypes.get }));
            });
    };

export const persistTestCases = (testCases: TestCase[]) =>
    async (dispatch: (arg0: any) => void) => {
        const trimmedTestCases = testCases.map(x => cloneTestCase(x)) //TestResult and TestCaseChange should not be persisted
        dispatch(sliceActions.startCall({ callType: callTypes.put }));
        return putTestCases(trimmedTestCases)
            .then(response => {
                const receivedTestCases = response
                dispatch(sliceActions.testCasesFetched(receivedTestCases));
            })
            .catch(err => {
                const alert = createDetailedAlert(err, "Failure on PUT for Test Cases")
                dispatch(sliceActions.catchError({ alert, callType: callTypes.post }));
            });
    };

export const importTestCases = (file: File) =>
    async (dispatch: (arg0: any) => void) => {
        const text = await file.text()
        try {
            const testCases = convertCSVDataToTestCases(text)
            dispatch(sliceActions.testCasesImported(testCases));
        } catch (error: any) {
            const alert = {
                message: "Error during import of Test Cases",
                apiMessage: error.message,
                data: error.stack
            } as DetailedAlert
            dispatch(sliceActions.catchError({ alert, callType: callTypes.file }));
        }
    };

export const cancelTestCasesImport = () =>
    async (dispatch: (arg0: any) => void) => {
        dispatch(sliceActions.clearTestCasesImport());
    };

export const acceptTestCasesImport = () =>
    async (dispatch: (arg0: any) => void) => {
        dispatch(sliceActions.acceptTestCasesImport());
    };

export const fetchArticleTypes = () =>
    async (dispatch: (arg0: any) => void) => {
        dispatch(sliceActions.startCall({ callType: callTypes.get }));
        return getArticleTypes()
            .then(response => {
                const articleTypes = response
                dispatch(sliceActions.articleTypesFetched(articleTypes));
            })
            .catch(err => {
                const alert = createDetailedAlert(err, "Failure on GET for Article Types")
                dispatch(sliceActions.catchError({ alert, callType: callTypes.get }));
            });
    };

export const fetchBrands = () =>
    async (dispatch: (arg0: any) => void) => {
        dispatch(sliceActions.startCall({ callType: callTypes.get }));
        return getBrands()
            .then(response => {
                const articleTypes = response
                dispatch(sliceActions.brandsFetched(articleTypes));
            })
            .catch(err => {
                const alert = createDetailedAlert(err, "Failure on GET for Brands")
                dispatch(sliceActions.catchError({ alert, callType: callTypes.get }));
            });
    };

export const fetchChannels = () =>
    async (dispatch: (arg0: any) => void) => {
        dispatch(sliceActions.startCall({ callType: callTypes.get }));
        return getChannels()
            .then(response => {
                const channels = response
                dispatch(sliceActions.channelsFetched(channels));
            })
            .catch(err => {
                const alert = createDetailedAlert(err, "Failure on GET for Channels")
                dispatch(sliceActions.catchError({ alert, callType: callTypes.get }));
            });
    };

export const upsertRoutingRule = (rule: IRoutingRule) =>
    async (dispatch: (arg0: any) => void) => {
        dispatch(sliceActions.upsertRoutingRule(rule));
    };

export const updateRuleSetDescription = (description: string) =>
    async (dispatch: (arg0: any) => void) => {
        dispatch(sliceActions.updateRuleSetDescription(description));
    };

export const upsertTestCase = (testCase: TestCase) =>
    async (dispatch: (arg0: any) => void) => {
        dispatch(sliceActions.upsertTestCase(testCase));
    };

export const runTestCases = (ruleSet: IRoutingRuleSet, testcases: TestCase[], description?: string) =>
    async (dispatch: (arg0: any) => void) => {
        dispatch(sliceActions.startCall({ callType: callTypes.post }));
        const updatedRuleSet = {
            ...ruleSet,
            description: description
        } as IRoutingRuleSet
        return runTestSuite(updatedRuleSet, testcases)
            .then(response => {
                const testResults = response
                dispatch(sliceActions.testSuiteResultsFetched(testResults));
            })
            .catch(err => {
                const alert = createDetailedAlert(err, "Failure on POST for Run Test Suite")
                dispatch(sliceActions.catchError({ alert, callType: callTypes.post }));
            });
    };

export const dismissAlert = () =>
    async (dispatch: (arg0: any) => void) => {
        dispatch(sliceActions.dismissAlert());
    };

export const discardTestResults = () =>
    async (dispatch: (arg0: any) => void) => {
        dispatch(sliceActions.discardTestResults());
    };