import BootstrapTable from "react-bootstrap-table-next";
import EditIconWithCopy from "../../svg/EditIconWithCopy";
import { ProductionSite, ShippingConfiguration } from "../models/ProductionSitesModel";
import { useProductionSitesContext } from "../redux/ProductionSitesContext";
import CheckIconWithCopy from "../../svg/CheckIconWithCopy";

const ProductionSitesTable = () => {
    const productionSitesContext = useProductionSitesContext()
    const productionSites = [...productionSitesContext.productionSites] as ProductionSite[]
    const sortedProductionSites = productionSites.sort((a, b) => a.code.localeCompare(b.code))

    const handleEditProductionSiteClick = (productionSite: ProductionSite) => {
        productionSitesContext.setSelectedProductionSite(productionSite)
        productionSitesContext.uiEvents.showProductionSiteEditModal()
    }

    const columns = [
        {
            dataField: 'code',
            text: 'Code',
            classes: 'col-1',
        },
        {
            dataField: 'name',
            text: 'Location',
            formatter: (cell: string, row: ProductionSite) => {
                return (
                    <>
                        <div>{row.name}</div>
                        <div>{row.countryCode}</div>
                        <div>{row.ianaTimeZone}</div>
                    </>
                )
            },
            classes: 'col-2',
        },
        {
            dataField: 'availableOperations',
            text: 'Available Operations',
            formatter: (cell: string[], row: ProductionSite) => {
                const options = cell?.map(x => x).sort() //map is needed to proved an editable array for sort to work on
                return (
                    <>
                        {options && options.length > 0 &&
                            options.map((option: string) => (
                                <div key={option}>{option}</div>
                            ))
                        }
                    </>
                )
            },
            classes: 'col-2',
        },
        {
            dataField: 'shippingConfiguration',
            text: 'Shipping',
            formatter: (cell: ShippingConfiguration, row: ProductionSite) => {
                return (
                    <>
                        {cell &&
                            <>
                                {cell.centiroSenderCode && <div>{`Centiro Sender: ${cell.centiroSenderCode}`}</div>}
                                {cell.eoriNumber && <div>{`EORI Number: ${cell.eoriNumber}`}</div>}
                                <div>{`Carrier Overrides: ${cell.carrierOverridesSupported}`}</div>
                            </>
                        }
                    </>
                )
            },
            classes: 'col-2',
        },
        {
            dataField: 'isActive',
            text: 'Active',
            formatter: (cell: boolean, row: ProductionSite) => (
                <div className='table-action-icons-container-leftaligned'>
                    <CheckIconWithCopy
                        copy='isActive'
                        customStyle='table-action-icons-children-small'
                        color={cell ? "#17B26A" : "#E4E6EF"}
                    />
                </div>
            ),
            sort: false,
            classes: 'col-1'
        },
        {
            dataField: 'actions',
            text: 'Actions',
            formatter: (cell: any, row: ProductionSite) => (
                <div className='table-action-icons-container-leftaligned'>
                    <button
                        onClick={() => handleEditProductionSiteClick(row)}
                        className='table-action-icons-container-button-small'
                    >
                        <EditIconWithCopy
                            copy='Edit'
                            customStyle='table-action-icons-children-small'
                        />
                    </button>
                </div>
            ),
            sort: false,
            classes: 'col-1'
        },
    ]

    return (
        <>
            <BootstrapTable
                wrapperClasses='table-responsive'
                bordered={false}
                classes='table table-head-custom table-vertical-center overflow-hidden semi-bold'
                bootstrap4
                keyField='code'
                data={sortedProductionSites}
                columns={columns}
                onTableChange={() => { }}
            >
            </BootstrapTable>
        </>
    );
}

export default ProductionSitesTable;