import { AxiosError } from 'axios';

export type DetailedAlert = {
    message: string,
    apiResponseCode?: string,
    apiMessage?: string,
    url?: string,
    data?: string
}

export const createDetailedAlert = (error: AxiosError, message: string) => {
    return {
        message: message,
        apiResponseCode: error.response?.status,
        apiMessage: error.message,
        url: error.config?.url,
        data: error.response?.data
    } as DetailedAlert
}