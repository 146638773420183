import { Card, CardBody, CardHeader } from '../../../_metronic/_partials/controls';
import ProductionSitesTable from './ProductionSitesTable';
import ProductionSiteActionCard from './ProductionSitesActionCard';
import { useProductionSitesContext } from '../redux/ProductionSitesContext';

type Props = {
    cardProps?: any;
}

const ProductionSitesCard = (props: Props) => {
    const productionSitesContext = useProductionSitesContext()
    const nrApiCallsInProgress = productionSitesContext.nrApiCallsInProgress

    return (
        <Card {...props.cardProps}>
            <CardHeader
                className='border-0'
                title="Production Sites"
                {...props.cardProps}
            >
            </CardHeader>
            <CardBody {...props.cardProps}>
                {(nrApiCallsInProgress > 0) &&
                    <div className='d-flex justify-content-center'>
                        <div className="overlay-layer bg-transparent">
                            <div className="spinner spinner-lg spinner-success" />
                        </div>
                    </div>
                }
                <ProductionSiteActionCard />
                <ProductionSitesTable />
            </CardBody>
        </Card>
    );
}

export default ProductionSitesCard;