import { getProductionSites } from '../../productionSites/api/ProductionSitesApi';
import { componentsSlice } from './ComponentsSlice';

const { actions } = componentsSlice;

export const selectPlant =
  (plantCode: string, plantSupportsCarrierConfiguration: boolean) =>
    (dispatch: (args: { payload: any; type: string }) => void) => {
      dispatch(
        actions.plantSelected({ plantCode, plantSupportsCarrierConfiguration })
      );
    };

export const fetchPlantData = () => async (dispatch: (arg0: any) => void) => {
  return getProductionSites().then((response: any) => {
    dispatch(actions.plantDataFetched({ response }));
  });
};
