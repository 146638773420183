import React, { createContext, ReactNode, useState, useContext } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { ProductionSitesData } from "./ProductionSitesSlice";
import { ProductionSite } from "../models/ProductionSitesModel";

export interface ProductionSitesContextProps extends ProductionSitesData {
    selectedProductionSite: ProductionSite,
    setSelectedProductionSite: (selectedProductionSite: ProductionSite) => void
    uiEvents: ProductionSitesUIEvents
}

type ProductionSitesUIEvents = {
    showProductionSiteEditModal: () => void,
    hideProductionSiteEditModal: () => void
}

const initialProductionSite = {
} as ProductionSite

const initialProductionSiteUIEvents = {
    showProductionSiteEditModal: () => { },
    hideProductionSiteEditModal: () => { }
} as ProductionSitesUIEvents

const initialState: ProductionSitesContextProps = {
    productionSites: [],
    nrApiCallsInProgress: 0,
    alert: null,
    selectedProductionSite: initialProductionSite,
    setSelectedProductionSite: (selectedProductionSite: ProductionSite) => { },
    uiEvents: initialProductionSiteUIEvents
};

const ProductionSitesContext = createContext<ProductionSitesContextProps>(initialState);

export function useProductionSitesContext() {
    return useContext(ProductionSitesContext);
}

type Props = {
    productionSitesUIEvents: ProductionSitesUIEvents,
    children: ReactNode
};

const ProductionSitesProvider = (props: Props) => {
    const [selectedProductionSite, setSelectedProductionSite] = useState<ProductionSite>(initialProductionSite)

    const { productionSitesData } = useSelector(
        (state: any) => ({
            productionSitesData: state.productionSites as ProductionSitesData
        }),
        shallowEqual
    );

    const value = {
        ...productionSitesData,
        selectedProductionSite: selectedProductionSite,
        setSelectedProductionSite: setSelectedProductionSite,
        uiEvents: props.productionSitesUIEvents
    };
    return <ProductionSitesContext.Provider value={value}>{props.children}</ProductionSitesContext.Provider>;
}

export default ProductionSitesProvider;