import { productionSitesSlice, callTypes } from "./ProductionSitesSlice";
import { getProductionSites, upsertProductionSite } from '../api/ProductionSitesApi';
import { createDetailedAlert } from "../../utils/DetailedAlert";
import { ProductionSite } from "../models/ProductionSitesModel";

const { actions: sliceActions } = productionSitesSlice;

export const dismissAlert = () =>
    async (dispatch: (arg0: any) => void) => {
        dispatch(sliceActions.dismissAlert());
    };

export const fetchProductionSites = () =>
    async (dispatch: (arg0: any) => void) => {
        dispatch(sliceActions.startCall({ callType: callTypes.get }));
        return getProductionSites()
            .then(response => {
                const productionSites = response
                dispatch(sliceActions.productionSitesFetched(productionSites));
            })
            .catch(err => {
                const alert = createDetailedAlert(err, "Failure on GET for Production Sites")
                dispatch(sliceActions.catchError({ alert, callType: callTypes.get }));
            });
    };

export const saveProductionSite = (productionSite: ProductionSite) =>
    async (dispatch: (arg0: any) => void) => {
        dispatch(sliceActions.startCall({ callType: callTypes.put }));
        return upsertProductionSite(productionSite)
            .then(() => {
                dispatch(sliceActions.endCall({ callType: callTypes.put }));
                dispatch(fetchProductionSites())
            })
            .catch(err => {
                const alert = createDetailedAlert(err, "Failure on PUT for Production Site")
                dispatch(sliceActions.catchError({ alert, callType: callTypes.get }));
            });
    };

