import { ProductionSite } from '../models/ProductionSitesModel';
import { useProductionSitesContext } from '../redux/ProductionSitesContext';

const newProductionSite = {
    code: 'NEW_SITE'
} as ProductionSite

const ProductionSiteActionCard = () => {
    const productionSitesContext = useProductionSitesContext()
    const setSelectedProductionSite = productionSitesContext.setSelectedProductionSite
    const showProductionSiteEditModal = productionSitesContext.uiEvents.showProductionSiteEditModal

    const handleAddSiteClick = () => {
        setSelectedProductionSite(newProductionSite)
        showProductionSiteEditModal()
    }

    return (
        <div className="d-flex justify-content-between bg-secondary rounded p-3 row">
            <div className="d-flex col">
            </div>
            <button
                className="border-0 bg-white rounded mx-2 col-2"
                onClick={handleAddSiteClick}
                onMouseDown={e => e.preventDefault()}
            >
                <div className="d-flex justify-content-center align-content-center">
                    <div className="h1 text-primary mt-2">+</div>
                    <div className="ml-3 mt-4">Add new site</div>
                </div>
            </button>
        </div>
    )
}

export default ProductionSiteActionCard