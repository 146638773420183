import { createSlice } from '@reduxjs/toolkit';
import { ProductionSite } from '../../productionSites/models/ProductionSitesModel';

export const PLANT_CONFIG_KEY = 'plantConfig';

type ComponentState = {
  plantCode: string;
  plantSupportsCarrierConfiguration: boolean;
  productionSites: ProductionSite[];
};

export type ProductionSiteData = {
  plantCode: string;
  timeZoneName: string;
  timeZoneLocation: string;
  plantSupportsCarrierConfiguration: boolean;
  currentOffset: string;
  flag: string;
  isActive: boolean;
};

export type ProductionSiteList = ProductionSiteData[];

const initialComponentState: ComponentState = {
  plantCode: 'YPB',
  plantSupportsCarrierConfiguration: true,
  productionSites: [],
};

export const componentsSlice = createSlice({
  name: 'components',
  initialState: initialComponentState,
  reducers: {
    plantSelected: (state, action) => {
      state.plantCode = action.payload.plantCode;
      state.plantSupportsCarrierConfiguration =
        action.payload.plantSupportsCarrierConfiguration;
    },
    plantDataFetched: (state, action) => {
      state.productionSites = action.payload.response;
    },
  },
});
