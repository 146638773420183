import { Modal } from "react-bootstrap";
import { Field, Form, Formik, FormikProps } from "formik";
import { Input, Select } from "../../../_metronic/_partials/controls";
import * as actions from '../redux/ProductionSitesActions'
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import * as countries from 'i18n-iso-countries';
import * as timezones from '@vvo/tzdb';
import { ProductionSite } from "../models/ProductionSitesModel";
import { useProductionSitesContext } from "../redux/ProductionSitesContext";

const ProductionSiteEditModal = () => {
    const dispatch = useDispatch();
    const productionSitesContext = useProductionSitesContext()
    const selectedProductionSite = productionSitesContext.selectedProductionSite
    const hideProductionSiteEditModal = productionSitesContext.uiEvents.hideProductionSiteEditModal

    const productionSite = {
        ...selectedProductionSite,
        shippingConfiguration: { ...selectedProductionSite.shippingConfiguration }
    } as ProductionSite

    const handleCancelClick = () => {
        hideProductionSiteEditModal()
    }

    const availableOperations = [
        "AddressChange",
        "PartialCancellation",
        "ProductionNotification",
        "ReuploadWithoutCancellation"
    ]

    const countryCodes = Object.keys(countries.getAlpha2Codes())
    const timeZones = timezones.getTimeZones().map((tz: any) => tz.name)

    const productionSiteValidation = Yup.object().shape({
        code: Yup.string()
            .required("Please enter a short Production Site Code")
            .min(2, "Code must be at least 2 characters"),
        name: Yup.string()
            .required("Please enter a Production Site Name"),
        countryCode: Yup.string()
            .min(2, "Country Code must be a valid ISO 3166 code")
            .max(2, "Country Code must be a valid ISO 3166 code")
            .required("Please enter a valid Country Code")
            .oneOf(countryCodes, "Country Code must be a valid ISO 3166 code"),
        ianaTimeZone: Yup.string()
            .required("Please enter a valid IANA Time Zone")
            .oneOf(timeZones, "Please enter a valid IANA Time Zone"),
    });

    const handleCopyToClipboard = (code: string) => {
        const securityGroupText =
            `Please create new Security groups for the MyFactory functions "${code}-READ" and "${code}-WRITE".
The groups should grant their members the MyFactory roles "${code}ReadAccess" and "${code}WriteAccess" respectively.
Granting users access to the groups should be a function of the "Application Access - MyFactory Access" page of the IT Service Desk`;

        navigator.clipboard.writeText(securityGroupText)
            .catch(err => console.error('Failed to copy text: ', err));
    };

    const createWarningSideBar = (formikProps: FormikProps<ProductionSite>) => {
        const warnForSecurityGroups = formikProps.values.code !== selectedProductionSite.code || selectedProductionSite.code === 'NEW_SITE'
        const warnForDeactivate = !formikProps.values.isActive && formikProps.touched.isActive
        const warnForInvisible = !formikProps.values.isVisible && formikProps.touched.isVisible
        if (!warnForSecurityGroups && !warnForDeactivate && !warnForInvisible) {
            return (<></>)
        }

        return (
            <div className="width-set-50">
                {warnForSecurityGroups &&
                    <div className="ml-5 bg-secondary rounded p-3 mb-3">
                        <h4 className="text-danger">Warning!</h4>
                        <div>
                            Adding a new Production Site or changing the Code for an existing one will require new Security Groups to be created.
                        </div>
                        <div>
                            Without Security Groups matching the Production Site Code configuring the Production Site in MyFactory will not be possible.
                        </div>
                        <div>
                            To create the Security Groups please create a Security Request through the Service Desk.
                        </div>
                        <div
                            className="add-to-clipboard cursor-pointer text-primary"
                            onClick={() => handleCopyToClipboard(formikProps.values.code)}
                        >
                            Click here to copy the required request text to the clipboard
                        </div>
                    </div>
                }
                {warnForDeactivate &&
                    <div className="ml-5 bg-secondary rounded p-3 mb-3">
                        <h4 className="text-danger">Warning!</h4>
                        <div>
                            Deactivating a Production Site will result in it no longer being available as a target in Routing Rules.
                        </div>
                        <div>
                            However, the currently active Routing Rules will not be updated.
                        </div>
                        <div>
                            Please make sure to remove all references to this Production Site in Routing Rules before deactivating it.
                        </div>
                    </div>
                }
                {warnForInvisible &&
                    <div className="ml-5 bg-secondary rounded p-3 mb-3">
                        <h4 className="text-danger">Warning!</h4>
                        <div>
                            Making a Production Site invisible will remove it from the MyFactory UI.
                        </div>
                        <div>
                            Configuring its LeadTime, Packaging etc. will no longer be possible.
                        </div>
                        <div>
                            Please make sure this is what you intend to do, because it cannot be reverted in MyFactory.
                        </div>
                    </div>
                }
            </div>
        )
    }

    return (
        <Modal size="lg" show={true} aria-labelledby="example-modal-sizes-title-lg" onEscapeKeyDown={handleCancelClick}>
            <Modal.Header>
                <Modal.Title id="example-modal-sizes-title-lg">
                    Edit Production Site
                </Modal.Title>
            </Modal.Header>
            <Formik
                initialValues={productionSite}
                validationSchema={productionSiteValidation}
                validateOnChange={false}
                validateOnBlur={true}
                onSubmit={(productionSite, formikBag) => {
                    dispatch(actions.saveProductionSite(productionSite))
                    hideProductionSiteEditModal()
                }}>
                {formikProps => (
                    <Form className="form">
                        <Modal.Body>
                            <div className="d-flex">
                                <div>
                                    <div className="form-row">
                                        <div className="form-group col-4">
                                            <label htmlFor="site-edit-code">Code</label>
                                            <Field
                                                component={Input}
                                                id="site-edit-code"
                                                name="code"
                                                type="string"
                                                onInput={(e: any) => e.target.value = e.target.value.toUpperCase()}
                                            />
                                        </div>
                                        <div className="form-group col-8">
                                            <label htmlFor="site-edit-name">Production Site Name</label>
                                            <Field
                                                component={Input}
                                                id="site-edit-name"
                                                name="name"
                                                placeholder='e.g. "Ypenburg, The Netherlands"'
                                                type="string"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-4">
                                            <label htmlFor="site-edit-country">CountryCode</label>
                                            <Field
                                                component={Input}
                                                id="site-edit-country"
                                                name="countryCode"
                                                placeholder='e.g. "NL"'
                                                type="string"
                                                onInput={(e: any) => e.target.value = e.target.value.toUpperCase()}
                                            />
                                        </div>
                                        <div className="form-group col-8">
                                            <label htmlFor="site-edit-timezone">IANA Time Zone</label>
                                            <Field
                                                component={Input}
                                                id="site-edit-timezone"
                                                name="ianaTimeZone"
                                                placeholder='e.g. "Europe/Amsterdam"'
                                                type="string"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-4">
                                            <label htmlFor="site-edit-operations">Available Operations</label>
                                            <Field
                                                component={Select}
                                                id="site-edit-operations"
                                                name="availableOperations"
                                                type="string"
                                                multiple
                                            >
                                                {availableOperations.map(option => (
                                                    <option key={option} value={option}>{option}</option>
                                                ))}
                                            </Field>
                                        </div>
                                        <div className="form-group col-8">
                                            <div className="form-group">
                                                <label htmlFor="site-edit-centirosender">Centiro Sender</label>
                                                <Field
                                                    component={Input}
                                                    id="site-edit-centirosender"
                                                    name="shippingConfiguration.centiroSenderCode"
                                                    placeholder='e.g. "NL@albelli.com"'
                                                    type="string"
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="site-edit-eori">EORI Number</label>
                                                <Field
                                                    component={Input}
                                                    id="site-edit-eori"
                                                    name="shippingConfiguration.eoriNumber"
                                                    type="string"
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="site-edit-overrides">Support Carrier Overrides</label>
                                                <Field
                                                    component={Input}
                                                    id="site-edit-overrides"
                                                    name="shippingConfiguration.carrierOverridesSupported"
                                                    type="checkbox"
                                                    className="form-check-input ml-2"
                                                />
                                                <small className="form-text text-muted">
                                                    Enable this to allow carrier configuration overrides for this production site
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {createWarningSideBar(formikProps)}
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="d-flex justify-content-between width-set-100">
                                <div className="d-flex justify-content-start">
                                    <button
                                        type="button"
                                        onClick={() => {
                                            formikProps.setFieldValue('isActive', !formikProps.values.isActive)
                                            formikProps.setFieldTouched('isActive', true)
                                        }}
                                        className={`btn btn-${formikProps.values.isActive ? "primary" : "outline"}-theme border-2 fw-bold mr-5 mb-sm-5 mb-md-0`}>
                                        {formikProps.values.isActive ? 'Deactivate' : 'Activate'}
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => {
                                            formikProps.setFieldValue('isVisible', !formikProps.values.isVisible)
                                            formikProps.setFieldTouched('isVisible', true)
                                        }}
                                        disabled={formikProps.values.isActive && formikProps.values.isVisible}
                                        className={`btn btn-${formikProps.values.isVisible ? "primary" : "outline"}-theme border-2 fw-bold mr-5 mb-sm-5 mb-md-0`}>
                                        {formikProps.values.isVisible ? 'Make inVisible' : 'Make Visible'}
                                    </button>
                                </div>
                                <div className="d-flex justify-content-end">
                                    <button
                                        type="button"
                                        onClick={handleCancelClick}
                                        className='btn btn-outline-theme border-2 fw-bold mr-5 mb-sm-5 mb-md-0'>
                                        Cancel
                                    </button>
                                    <button
                                        type="submit"
                                        className='btn btn-primary-theme border-2 fw-bold mr-5 mb-sm-5 mb-md-0'>
                                        Save
                                    </button>
                                </div>
                            </div>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik >
        </Modal >
    );
}

export default ProductionSiteEditModal;