import { createSlice } from '@reduxjs/toolkit';
import { DetailedAlert } from '../../utils/DetailedAlert';

export interface ProductionSitesData {
    productionSites: [],
    nrApiCallsInProgress: number,
    alert: DetailedAlert | null
};

const initialProductionSitesState: ProductionSitesData = {
    productionSites: [],
    nrApiCallsInProgress: 0,
    alert: null
};

export const callTypes = {
    get: 'get',
    post: 'post',
    put: 'put',
    file: 'file'
};

export const productionSitesSlice = createSlice({
    name: 'productionSites',
    initialState: initialProductionSitesState,
    reducers: {
        catchError: (state, action) => {
            state.alert = action.payload.alert;
            if (action.payload.callType === callTypes.get ||
                action.payload.callType === callTypes.post ||
                action.payload.callType === callTypes.put) {
                state.nrApiCallsInProgress = Math.max(state.nrApiCallsInProgress - 1, 0);
            }
        },
        startCall: (state, action) => {
            state.alert = null;
            if (action.payload.callType === callTypes.get ||
                action.payload.callType === callTypes.post ||
                action.payload.callType === callTypes.put) {
                state.nrApiCallsInProgress = state.nrApiCallsInProgress + 1;
            }
        },
        endCall: (state, action) => {
            state.alert = null;
            if (action.payload.callType === callTypes.get ||
                action.payload.callType === callTypes.post ||
                action.payload.callType === callTypes.put) {
                state.nrApiCallsInProgress = Math.max(state.nrApiCallsInProgress - 1, 0);
            }
        },
        dismissAlert: (state) => {
            state.alert = null;
        },
        productionSitesFetched: (state, action) => {
            state.nrApiCallsInProgress = Math.max(state.nrApiCallsInProgress - 1, 0);
            state.alert = null;
            state.productionSites = action.payload;
        }
    }
});